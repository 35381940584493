.filter-search {
   display: flex;
   flex-direction: row;
   justify-content: stretch;
   align-items: center;
   background: var(--background-white);
   border: 1px solid var(--background-divider);
   border-radius: 8px;
   position: relative;
   min-width: 180px;
   max-width: 180px;
   cursor: text;

   .filter-search-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 0px 8px 16px;
      gap: 8px;

      .search-icon {
         display: flex;
         width: 12px;
         height: 12px;
         background-image: url(/public/images/search.svg);
         background-size: 12px;
      }

      .filter-input {
         display: flex;
         flex-grow: 1;
         position: relative;
         border: none;
         width: 110px;
      }

      .filter-input:focus {
         outline: none;
      }

      .filter-input:focus::placeholder {
         color: transparent;
      }
   }

   .filter-dropdown-dropdown {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 16px 12px;
      gap: 5px;
      position: absolute;
      top: 42px;
      left: 0px;
      background: var(--background-white);
      box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      width: 155px;
      z-index: 1;
      cursor: auto;
      max-height: 300px;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
   }

   .filter-dropdown-dropdown::-webkit-scrollbar {
      display: none;
   }
}
