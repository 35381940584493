.welcome {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   height: 100%;
   flex: 1 1 auto;

   .app-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .welcome-content {
         display: flex;
         flex-grow: 0;
         order: 1;
      }

      .button-link-create-account {
         display: flex;
         order: 2;
         margin-top: 60px;
         position: relative;
         background-color: var(--primary-dark-5);
      }

      .image-background {
         display: flex;
         align-items: center;
         flex-direction: column;
         flex: 1 1 auto;
         order: 3;
         width: 100%;
         margin-top: -28px;
         background-color: var(--background-divider);
         overflow-y: hidden;

         .preview {
            display: relative;
            width: clamp(0px, 95vw, 1019px);
            height: 600px;
            overflow-y: hidden;
            margin-top: 75px;
            background-image: url(/public/images/preview-duidash-large.png);
            background-size: clamp(0px, 95vw, 1019px);
            background-repeat: no-repeat;

            img {
               display: relative;
            }
         }
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .welcome > .app-body > .image-background > .preview {
      display: relative;
      width: 219px;
      height: 401px;
      margin-top: 75px;
      background-image: url(/public/images/preview-duidash-small.png);
      background-size: 219px;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .welcome > .app-body > .image-background > .preview {
      width: clamp(0px, 95vw, 617px);
      height: 400px;
      background-image: url(/public/images/preview-duidash-large.png);
      background-size: clamp(0px, 95vw, 617px);
      background-repeat: no-repeat;
   }
}
