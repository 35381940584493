.sort-filter {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin-top: 48px;
   margin-bottom: 42px;

   .sort-filter-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      width: 80%;

      .sort-button {
         background-color: var(--primary-dark-5);
         display: flex;
         margin-left: auto;
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .sort-filter {
      margin-right: 0px;
      margin-left: 15px;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 4px;

      .sort-filter-wrapper {
         gap: 4px;
         width: 95%;
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .sort-filter {
      gap: 6px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 95%;

      .sort-filter-wrapper{
         width: 95%;
      }
   }
}
