.tiny-button {
   border: none;
   background: var(--background-divider);
   color: #000000;
   border-radius: 4px;
   padding: 4px 8px;
   cursor: pointer;
}

.tiny-button:hover {
   color: var(--primary-dark-2);
}

.tiny-button:active {
   background: var(--primary-dark-1);
   color: var(--primary-dark-4);
}

.tiny-button-disabled {
   border: none;
   background: var(--background-divider);
   border-radius: 4px;
   padding: 4px 8px;
   color: var(--primary-dark-2);
   cursor: auto;
}

.tiny-button-disabled:hover {
   color: var(--primary-dark-2);
}

.tiny-button-disabled:active {
   background: var(--background-divider);
   color: var(--primary-dark-2);
}
