.toast-message {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   position: fixed;
   top: -30%;
   padding: 10px 16px 10px 8px;
   background: #ffffff;
   box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.15);
   border-radius: 8px;
   z-index: 14;
   width: 70%;
   max-width: 600px;
   min-height: 51px;
   margin: 5px;
   left: 50%;
   gap: 12px;
   transform: translate(-50%, -100%);
   opacity: 0;
   transition: opacity 0.5s ease, transform 0.5s ease;
   border: 1px solid #ecbcbc;

   &.toast-visible {
      top: 10%;
      opacity: 1;
      transform: translate(-50%, 0);
   }

   &.toast-hidden {
      top: -30%;
      opacity: 0;
      transform: translate(-50%, -100%);
   }

   &.success {
      background: linear-gradient(180deg, rgba(148, 240, 148, 0.1) 0%, rgba(0, 214, 0, 0.1) 100%), #ffffff;
      border: 1px solid #8dd28c;
      /* Optional success border */
   }

   &.error {
      background: linear-gradient(180deg, rgba(240, 163, 163, 0.1) 0%, rgba(214, 0, 0, 0.1) 100%), #ffffff;
      border: 1px solid #d60000;
      /* Optional error border */
   }

   .toast-message-nav-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .toast-message-header {
         align-self: center;
         color: var(--primary-dark-1);
         margin-left: 10px;
      }

      .toast-message-back {
         color: var(--primary-dark-1);
         cursor: pointer;
         margin-left: auto;
         height: 10px;
         width: 14px;
         background-image: url(/public/images/arrow-red.svg);
         transition: transform 0.3s ease;
      }

      .toast-message-back:hover {
         color: var(--primary-dark-2);
         background-image: url(/public/images/back-arrow-hover.svg);
         transform: rotate(180deg);
      }
   }

   .toast-message-body {
      color: var(--primary-dark-2);
      margin-left: 23px;
   }
}

@media only screen and (max-device-width: 744px) {
   .toast-message {
      top: 0%;
      width: 90%;
      padding: 16px;
      gap: 8px;
   }

   .toast-message-nav-wrapper {
      gap: 5px;
   }
}
