.customer-details {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   flex: auto;
   height: 100%;

   .app-body {
      display: flex;
      flex: auto;
      flex-direction: column;
      align-items: stretch;
      background: var(--background-gray);

      .customer-details-body {
         display: flex;
         flex-direction: row;
         justify-content: stretch;
         align-items: stretch;
         flex: 1;
         height: 100%;

         .customer-details-left {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            flex-grow: 2;
            height: 100%;

            .back-button {
               align-self: flex-start;
               margin-top: 24px;
               margin-left: 3%;
            }

            .customer-details-left-body-wrapper-row {
               display: flex;
               flex-direction: row;
               justify-content: center;
               align-items: stretch;

               .customer-details-left-body-wrapper-column {
                  display: flex;
                  flex-direction: column;
                  justify-content: stretch;
                  align-items: stretch;
                  flex-grow: 0.3;
               }
            }
         }
      }
   }
}
