.app-footer {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   background-color: var(--secondary-gray);
   width: 100%;
   flex-shrink: 0;

   .footer-logos {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;
      margin: 32px 39px 28px 62px;

      .cst-logo-monochrome {
         margin-right: auto;
         width: 120px;
      }

      .footer-social-logo {
         cursor: pointer;
      }
   }

   .footer-links {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      max-width: 465px;
      margin-left: 62px;
      margin-bottom: 32px;
      margin-right: 20px;
      gap: 16px;

      .footer-link {
         cursor: pointer;
         color: var(--background-white);
         text-decoration: none;
      }
   }

   .footer-copyright {
      color: var(--primary-dark-3);
      margin-left: 62px;
      margin-bottom: 46px;
   }

   .footer-extra-height {
      visibility: hidden;
      display: none;
   }
}

@media only screen and (max-device-width: 390px) {
   .app-footer {
      .footer-logos {
         .cst-logo-monochrome {
            width: 61px;
         }
      }

      .footer-logos {
         margin-left: 31px;
      }

      .footer-links {
         flex-direction: column;
         align-items: flex-start;
         margin-left: 31px;
      }

      .footer-copyright {
         margin-left: 31px;
      }

      .footer-extra-height {
         visibility: inherit;
         display: inherit;
         height: 80px;
      }
   }
}

@media only screen and (min-device-width: 390px) and (max-device-width: 744px) {
   .app-footer {
      .footer-extra-height {
         visibility: inherit;
         display: inherit;
         height: 80px;
      }
   }
}
