.invite-modal {
   display: flex;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow-y: auto;
   flex-direction: column;
   align-items: center;
   z-index: 11;
   background-color: rgba(0, 0, 0, 0.5);

   .invite-modal-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 30px 39px 20px 39px;
      background: var(--background-white);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      z-index: 12;
      width: 70%;
      max-width: 605px;
      gap: 16px;
      margin: auto;

      .error-message {
         font-family: 'Roboto';
         color: var(--secondary-attention);
         font-size: 0.9rem;
      }

      .invite-modal-body {
         color: var(--primary-dark-2);
         margin-bottom: 34px;
      }

      .invite-modal-nav-wrapper {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         align-items: center;

         .invite-modal-back {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary-dark-1);
            cursor: pointer;

            .back-arrow {
               margin-right: 7px;
               height: 10px;
               width: 14px;
               background-image: url(/public/images/back-arrow.svg);
            }
         }

         .invite-modal-back:hover {
            color: var(--primary-dark-2);

            .back-arrow {
               background-image: url(/public/images/back-arrow-hover.svg);
            }
         }
      }

      .primary-button {
         margin-top: 16px;
         align-self: center;
      }

      .invite-modal-success-header {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 14px;
         margin-top: 50px;
         align-self: center;
         color: var(--secondary-success);
      }

      .invite-modal-success-text {
         max-width: 456px;
         align-self: center;
         text-align: center;
         color: var(--primary-dark-2);
         margin-bottom: 50px;

         .invite-success-email {
            color: var(--primary-dark-1);
         }
      }
   }
}

.form-group {
   font-family: 'Roboto';
   display: flex;
   flex-direction: column;
   align-items: stretch;
   background-color: var(--background-gray);
   border: 1px solid;
   border-color: var(--background-divider);
   border-radius: 8px;
   padding: 10px 22px 10px 16px;
   gap: 4px;
   cursor: text;

   label {
      color: var(--primary-dark-2);
   }

   select {
      font-family: 'Roboto';
      border: none;
      background: var(--background-gray);
      color: var(--primary-dark-1);
      font-size: 1rem;
      padding: 8px 0;
      cursor: pointer;

      &:focus {
         outline: none;
         border-bottom: 1px solid var(--primary-green);
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
      }
   }

   .error-message {
      color: var(--secondary-attention);
      font-size: 0.875rem;
   }
}


.invite-modal-hidden {
   display: none;
   visibility: hidden;
}

@media only screen and (max-device-width: 744px) {
   .invite-modal > .invite-modal-wrapper {
      padding: 32px;
      width: 75%;
   }
}
