.sort-button {
   display: flex;
   border-radius: 8px;
   border-width: 0px;
   width: 40px;
   height: 40px;
   justify-content: center;
   align-items: center;
   position: relative;
   cursor: pointer;

   img {
      filter: invert(100%);
   }

   .checkbox {
      visibility: hidden;
      display: none;
   }
}

.sort-button-checked {
   background: var(--primary-green);
}

.sort-button-checked:hover {
   background: var(--primary-green);
}

.sort-button-not-checked {
   background: var(--primary-dark-1);
}

.sort-button-not-checked:hover {
   background: var(--primary-dark-2);
}

.sort-button-disabled {
   background: var(--primary-dark-2);
   cursor: auto;
}

.sort-button-disabled:hover {
   background: var(--primary-dark-2);
}

@media only screen and (max-device-width: 390px) {
   .sort-button {
      margin-right: 24px;
   }
}
