.employee-sort-filter {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 25px auto;
   width: 80%;

   .employee-filters-sort {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;

      .sort-button {
         background-color: var(--primary-dark-5);
         display: flex;
         margin-left: auto;
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .employee-sort-filter {
      width: 90%;

      .employee-filters-sort {
         .sort-button {
            margin-right: 0;
         }
      }

   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .employee-sort-filter {
      width: 90%;
   }
}
