.clear-button {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   border: none;
   border-radius: 55px;
   width: 30px;
   height: 30px;
   background: var(--background-divider);
   z-index: 1;
}

.clear-button:hover {
   background: var(--primary-dark-4);
}
