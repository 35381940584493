.calendar {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 8px 12px 8px 12px;
   background: var(--background-white);
   border: 1px solid var(--background-divider);
   border-radius: 8px;
   position: relative;
   cursor: pointer;

   .calendar-date {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 6px;
      margin-right: 8px;
      border-right: 1px solid var(--background-divider);
   }

   .calendar-icon {
      width: 20px;
      height: 20px;
      background-image: url(/public/images/calendar.svg);
   }
}

.calendar-active {
   .calendar-icon {
      width: 20px;
      background-image: url(/public/images/calendar-focused.svg);
   }
}

.hidden-day-picker {
   visibility: hidden;
   display: none;
}

.rdp {
   position: absolute;
   top: 30;
   right: -15px;
   background: var(--background-white);
   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
   border-radius: 12.9776px;
   padding: 20px;
   --rdp-cell-size: 44px;
   cursor: auto;
   z-index: 10;
}

.rdp-caption_label {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 300;
   font-size: 16px;
   line-height: 22px;
}

.rdp-head_cell {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   text-transform: capitalize;
}

.rdp-cell {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
}

.rdp-day_selected {
   color: var(--primary-green);
   background-color: var(--secondary-green-hue);
}

.rdp-day_selected:hover {
   color: var(--primary-green);
   background-color: var(--secondary-green-hue);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
   background-color: var(--secondary-green-hue);
}

.rdp-nav_button {
   box-shadow: 0px 1px 1px rgba(0, 14, 51, 0.05);
   border-radius: 65.9633px;
}

.rdp-day_range_end,
.rdp-day_range_start {
   background: var(--primary-green);
   color: var(--background-white);
   box-shadow: 0px 1.21665px 0.811101px rgba(0, 0, 0, 0.25),
      0px 2.4333px 1.6222px rgba(125, 125, 125, 0.2);
   border-radius: 5px;
}

.rdp-day_range_end:hover,
.rdp-day_range_start:hover {
   background: var(--primary-green);
   color: var(--background-white);
}

@media only screen and (max-device-width: 744px) {
   .calendar > .calendar-date {
      visibility: hidden;
      display: none;
   }
}
