.document-card {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   background: var(--background-white);
   border: 1px solid var(--background-divider);
   border-radius: 14px;
   padding: 16px 20px;

   .document-text {
      display: flex;
      flex-direction: column;
      margin-right: 8px;

      .customer-badges {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         gap: 8px;
         margin-bottom: .5rem;
      }

      .document-summary {
         color: var(--primary-dark-2);
         margin-bottom: 2px;
      }

      .document-date {
         color: var(--primary-dark-2);
         margin-bottom: 8px;
      }

      .document-type {
         color: var(--primary-dark-3);
      }
   }

   .download-button {
      margin-left: auto;
   }
}
