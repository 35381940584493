.secondary-button {
   border: 1px solid var(--primary-dark-4);
   padding: 12px 24px;
   background: none;
   border-radius: 160px;
   color: var(--primary-dark-2);
   cursor: pointer;
}

.secondary-button:active {
   background: var(--primary-dark-4);
}
