.affiliate {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   align-items: stretch;
   flex: 1;

   .app-body {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: stretch;
   }
}
