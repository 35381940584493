.welcome-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1;

   .body-large-semibold {
      color: var(--primary-green);
      display: flex;
      align-items: center;
      text-align: center;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-top: 40;
      margin-bottom: 5px;
   }

   .large-type {
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--primary-dark-2);
      order: 2;
      flex-grow: 0;
      flex: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 13px;
      margin-right: 13px;
      max-width: 713px;
   }

   .body-large-light {
      color: var(--primary-dark-3);
      display: flex;
      align-items: center;
      text-align: center;
      flex: none;
      order: 3;
      flex-grow: 0;
      margin-top: 5px;
      margin-bottom: 0px;
      margin-left: 42px;
      margin-right: 42px;
      max-width: 588px;
   }
}

@media only screen and (max-device-width: 390px) {
   .welcome-content > .body-large-light {
      max-width: 305px;
   }

   .welcome-content > .large-type {
      font-weight: 100;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0em;
      max-width: 355px;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .welcome-content > .body-large-light {
      max-width: 388px;
   }

   .welcome-content > .large-type {
      max-width: 435px;
   }
}
