.checkbox-text {
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
   padding: 8px 12px;
   gap: 8px;
   border: 1px solid;
   border-color: var(--primary-dark-4);
   border-radius: 8px;
   color: var(--primary-dark-2);
   min-width: 109px;
   cursor: pointer;

   .checkbox-check {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-color: var(--primary-dark-4);
      border-radius: 2.83333px;
      width: 16px;
      height: 16px;

      img {
         display: none;
         visibility: hidden;
      }
   }

   .checkbox {
      display: none;
      visibility: hidden;
   }
}

.checkbox-text-checked {
   color: var(--primary-dark-2);

   .checkbox-check > img {
      display: inline;
      visibility: inherit;
   }
}

@media only screen and (max-device-width: 744px) {
   .checkbox-text {
      margin-right: 21px;
   }
}
