.card-badge {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   border-radius: 160px;
   border: 1px solid;
   padding: 6px 8px;
   gap: 4px;

   .badge-icon {
      min-height: 12px;
      min-width: 12px;
      background-size: 12px 12px;
   }

   .label-xsmall-allcaps{
      white-space: nowrap;
   }
}

.new-client {
   background: linear-gradient(
      0deg,
      rgba(121, 182, 15, 0.17) 2.08%,
      rgba(199, 223, 157, 0.148) 100%
   );
   mix-blend-mode: multiply;
   border-color: #d4e4b8;
   .badge-icon {
      background-image: url(/public/images/badge-new-client.svg);
   }
}

.archive {
   background: rgba(104, 102, 114, 0.05);
   mix-blend-mode: multiply;
   border-color: #908f97;
   .badge-icon {
      background-image: url(/public/images/badge-archive.svg);
   }
}

.calibration-overdue {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
   mix-blend-mode: multiply;
   border-color: #f5e2bb;
   .badge-icon {
      background-image: url(/public/images/badge-alert.svg);
   }
}

.alert-device {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
   mix-blend-mode: multiply;
   border-color: #f5e2bb;
   .badge-icon {
      background-image: url(/public/images/badge-alert.svg);
   }
}

.recent-violation {
   background: linear-gradient(
      180deg,
      rgba(240, 163, 163, 0.1) 0%,
      rgba(214, 0, 0, 0.1) 100%
   );
   mix-blend-mode: multiply;
   border-color: #ecbcbc;
   .badge-icon {
      background-image: url(/public/images/badge-violation.svg);
   }
}
