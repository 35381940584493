.sort-dropdown {
   display: flex;
   flex-direction: column;
   width: 263px;
   position: absolute;
   top: 50px;
   left: -245px;
   background: var(--background-white);
   z-index: 10;
   padding: 16px 12px;
   gap: 5px;
   box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.05);
   border-radius: 10px;
   cursor: auto;

   .dropdown-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
   }
}

.hidden {
   visibility: hidden;
   display: none;
}
