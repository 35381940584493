.card-affiliate {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
   background-color: var(--background-white);
   border-radius: 8px;
   padding: 40px;
   border: 1px solid var(--background-white);
   min-height: 98px;
   flex-shrink: 0;
   min-width: 0;
   cursor: pointer;

   .card-affiliate-name {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      min-width: 0;

      .affiliate-header-wrapper {
         display: flex;
         flex-direction: row;
         align-items: center;

         .affiliate-unverified-label {
            color: #995900;
            margin-right: 8px;

            img {
               margin-right: 5px;
            }
         }
      }

      .affiliate-name {
         margin-top: 20px;
         color: var(--primary-dark-1);
         width: 700px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }

   .card-affiliate-firm {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      min-width: 0;

      .card-affiliate-location-wrapper {
         display: flex;
         flex-direction: row;
         justify-content: flex-end;
         align-items: center;
         gap: 8px;

         .card-affiliate-firm-city {
            color: var(--primary-dark-2);
            white-space: nowrap;
         }

         img {
            height: 2px;
         }

         .card-affiliate-firm-state {
            color: var(--primary-dark-2);
            white-space: nowrap;
         }
      }
   }
}

.card-affiliate:hover {
   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
   background: linear-gradient(
         90deg,
         rgba(43, 147, 255, 0.2) 0%,
         rgba(228, 241, 255, 0.2) 100%
      ),
      #ffffff;
}

.affiliate-unverified:hover {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
}

.affiliate-verified:hover {
   background: linear-gradient(
         90deg,
         rgba(43, 147, 255, 0.2) 0%,
         rgba(228, 241, 255, 0.2) 100%
      ),
      #ffffff;
}

.firm-associate-wrapper {
   display: flex;
   gap: 1.5rem;
   .card-affiliate-firm-name {
      text-align: center;
      color: var(--primary-dark-1);
   }
}

@media only screen and (max-width: 744px) {
   .card-affiliate {
      flex-direction: column;
      margin-left: 21px;
      margin-right: 21px;
      padding: 28px 22px 20px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      overflow: hidden;

      .card-affiliate-name {
         align-self: flex-start;
      }

      .card-affiliate-firm {
         align-items: flex-start;
      }
   }

   .affiliate-unverfied {
      background: linear-gradient(
            90deg,
            rgba(255, 201, 88, 0.2) 0%,
            rgba(255, 248, 233, 0.2) 100%
         ),
         #ffffff;
   }

   .affiliate-verfied {
      background: linear-gradient(
            90deg,
            rgba(43, 147, 255, 0.2) 0%,
            rgba(228, 241, 255, 0.2) 100%
         ),
         #ffffff;
   }

   .card-affiliate .card-affiliate-name .affiliate-name {
      width: 380px;
   }

}

@media only screen and (max-width: 390px) {
   .card-affiliate {
      flex-direction: column;
   }

   .card-affiliate .card-affiliate-name .affiliate-name {
      width: 280px;
   }
}
