.admin-profile-content {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   flex-grow: 0.5;
   background-color: var(--background-white);
   border: 1px solid var(--background-white);
   border-radius: 10px;
   padding: 40px 30px 42px 44px;
   margin-left: 20px;
   margin-right: 20px;
   margin-top: 18px;
   width: 60%;
   max-width: 800px;
   max-height: 150px;

   .admin-label {
      color: var(--primary-dark-2);
   }

   .label-xsmall-allcaps {
      color: var(--primary-dark-3);
   }

   .personal-label {
      margin-top: 41px;
   }

   .email-wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 21px;
      margin-left: 25px;

      .profile-email {
         margin-left: 16px;
         white-space: nowrap;
      }
   }
}

@media only screen and (max-device-width: 744px) {
   .admin-profile-content {
      width: 70%;
   }
}
