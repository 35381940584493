.affiliate-attorney {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   flex: 1;
   justify-content: flex-start;
   background-color: var(--background-white);

   .tab-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--primary-dark-4);
      transition: all .5s ease-in-out;

      .tab {
         display: flex;
         flex-direction: row;
         justify-content: flex-start;
         align-items: center;
         width: 80%;
         gap: 15px;

         .tab-button {
            font-family: 'Roboto';
            font-size: 36px;
            padding: 10px 20px;
            border: 1px solid var(--primary-dark-4);
            border-bottom: 0px;
            background-color: var(--background-white);
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
            color: var(--primary-dark-1);
            border-radius: 4px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            transition: background-color 0.3s;
            cursor: pointer;

            &:hover {
               background: linear-gradient(90deg, #2b93ff33 0%, #e4f1ff33 100%), #fff;
               color: var(--primary-dark-1);
            }

            &.active {
               color: var(--background-white);
               background-color: var(--primary-dark-1);
               border: 1px solid transparent;
               &:hover {
                  background-color: var(--primary-dark-1);
               }
            }
         }
      } 
   }
   
   .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      background: var(--background-gray);
      height: 100%;
      padding-top: 10px;
      padding-bottom: 50px;

      .attorney-content {
         display: flex;
         flex-direction: column;
         gap: 10px;
         margin-bottom: 20px;
         width: 75%;
         max-width: 1500px;

         .attorney-view-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-wrapper {
               display: flex;
               align-items: center;
               gap: .75rem;
            }
         }
      }
   }
}

@media only screen and (max-width: 744px) {
   .affiliate-attorney > .content-wrapper > .attorney-content {
      width: 95%;
   }

   .affiliate-attorney > .tab-container > .tab > .tab-button {
      font-size: 22px;   
   }
}
