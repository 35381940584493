.welcome-logos {
   background-color: var(--primary-dark-4);
   padding-top: 52px;
   padding-bottom: 52px;
   position: relative;
   min-width: 390px;

   .welcome-logos-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 32px;

      .cst-logo {
         width: 87px;
      }

      .intoxalock-logo {
         width: 116px;
      }

      .breathe-easy-logo {
         width: 118px;
      }

      .new-directions-logo {
         width: 52px;
      }
   }

   .scroll-wrapper-secondary {
      visibility: hidden;
      display: none;
   }
}

@media only screen and (max-device-width: 470px) {
   .welcome-logos {
      overflow-x: hidden;

      .welcome-logos-wrapper {
         top: 35%;
         position: absolute;
      }

      .scroll-wrapper-primary {
         animation: primary 10s linear infinite;
      }

      .scroll-wrapper-secondary {
         visibility: inherit;
         display: flex;
         animation: secondary 10s linear infinite;
      }
   }
   @keyframes primary {
      from {
         left: 0%;
      }
      to {
         left: -130%;
      }
   }

   @keyframes secondary {
      from {
         left: 130%;
      }
      to {
         left: 0%;
      }
   }
}
