.jurisdiction-dropdown {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 8px 0px 8px 16px;
   gap: 8px;
   position: relative;
   cursor: pointer;

   input {
      display: none;
      visibility: hidden;
   }

   .jurisdiction-dropdown-icon {
      height: 5px;
      width: 8px;
      background-image: url(/public/images/dropdown-arrow-down.svg);
      filter: invert(100%);
   }

   .jurisdiction-dropdown-dropdown {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 16px 12px;
      gap: 5px;
      position: absolute;
      top: 30px;
      right: 0px;
      background: var(--background-white);
      box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      z-index: 1;
      cursor: auto;
   }
}

.jurisdiction-dropdown-checked {
   .jurisdiction-dropdown-icon {
      background-image: url(/public/images/dropdown-arrow-up.svg);
   }
}
