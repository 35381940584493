.profile {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   height: 100%;
   flex: 1 1 auto;

   .app-body {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      flex: 1;
      background: var(--background-gray);

      .profile-body {
         display: flex;
         flex-direction: column;
         justify-content: stretch;
         align-items: center;
         height: 100%;

         .back-button {
            align-self: flex-start;
            margin-top: 24px;
            margin-left: 3%;
         }

         .profile-content,
         .admin-profile-content {
            margin-bottom: 27px;
         }

         .sign-out-button {
            margin-bottom: 35px;
         }
      }
   }
}
