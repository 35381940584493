.download-button {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-width: 42px;
   min-height: 42px;
   background: var(--primary-dark-1);
   border-radius: 8px;
   border: none;
   cursor: pointer;
}

.download-button:hover {
   background: var(--primary-dark-2);
}

.inverted {
   background: var(--background-gray);

   img {
      filter: invert(100%);
   }
}

.inverted:hover {
   background: var(--primary-dark-4);
}

.download-button-disabled {
   cursor: default;
   img {
      filter: invert(40%);
   }
}

.download-button-disabled:hover {
   background: var(--background-gray);
   pointer-events: none;
   cursor: default;
}
