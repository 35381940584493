.affiliate-details-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 1.25rem;

   .affiliate-details-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .affiliate-type {
         color: var(--primary-dark-2);
         margin-bottom: 20px;
      }

      .affiliate-details-unverified-label {
         color: #995900;
         margin-right: 8px;
         margin-bottom: 20px;

         img {
            margin-right: 5px;
         }
      }

      .affiliate-name {
         color: var(--primary-dark-1);
      }

      .affiliate-firm {
         margin-top: 8px;
         color: var(--primary-dark-2);
      }

      .affiliate-location-wrapper {
         margin-top: 8px;
         display: flex;
         flex-direction: row;
         gap: 8px;
         align-items: center;

         .affiliate-firm-city {
            color: var(--primary-dark-2);
         }

         img {
            height: 2px;
         }

         .affiliate-firm-state {
            color: var(--primary-dark-2);
         }
      }
   }

   .affiliate-details-interactive {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 20px;

      .affiliate-details-contact {
         display: flex;
         flex-direction: row;
         gap: 8px;
      }

      .affiliate-details-buttons {
         display: flex;
         flex-direction: column;
         gap: 16px;
         margin-top: 36px;
      }
   }
}
@media only screen and (max-device-width: 744px) {
   .affiliate-details-header {
      flex-direction: column;

      .affiliate-details-interactive {
         margin-top: 24px;
         margin-left: 0px;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;

         .affiliate-details-contact {
            margin-right: 8px;
         }
      }
   }
}
