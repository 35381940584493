.search-dropdown {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: stretch;
   position: absolute;
   top: 82px;
   left: 0px;
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
   border-radius: 0px 0px 20px 20px;
   width: 100%;
   background: var(--background-white);
   z-index: 10;
   gap: 8px;
   padding: 16px 0px 24px;

   .search-dropdown-info{
      text-align: center;
      color: var(--primary-dark-2);
   }
}

.search-dropdown-invisible {
   visibility: hidden;
   display: none;
}

@media only screen and (max-device-width: 390px) {
   .search-dropdown {
      top: 55px;
      border-radius: 0px 0px 10px 10px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      left: -4%;
      right: -4%;
      width: 108%;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .search-dropdown {
      top: 55px;
   }
}
