.help-modal {
   display: flex;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow-y: auto;
   flex-direction: column;
   align-items: center;
   z-index: 11;
   background-color: rgba(0, 0, 0, 0.5);

   .help-modal-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 30px 39px 20px 39px;
      background: var(--background-white);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      z-index: 12;
      width: 70%;
      max-width: 605px;
      gap: 16px;
      margin: auto;

      .help-modal-body {
         color: var(--primary-dark-2);
         margin-bottom: 34px;
      }

      .help-modal-nav-wrapper {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;

         .help-modal-back {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary-dark-1);
            cursor: pointer;

            .back-arrow {
               margin-right: 7px;
               height: 10px;
               width: 14px;
               background-image: url(/public/images/back-arrow.svg);
            }
         }

         .help-modal-back:hover {
            color: var(--primary-dark-2);

            .back-arrow {
               background-image: url(/public/images/back-arrow-hover.svg);
            }
         }
      }

      .primary-button {
         margin-top: 16px;
         align-self: center;
      }

      .toast-message {
         top: 0%;
         z-index: 1000;
      }

      .help-modal-success-header {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 14px;
         margin-top: 50px;
         align-self: center;
         color: var(--secondary-success);
      }

      .help-modal-success-text {
         max-width: 456px;
         align-self: center;
         text-align: center;
         color: var(--primary-dark-2);
         margin-bottom: 50px;
      }
   }
}

.help-modal-hidden {
   display: none;
   visibility: hidden;
}

@media only screen and (max-device-width: 744px) {
   .help-modal > .help-modal-wrapper {
      padding: 32px;
      width: 75%;
   }
}
