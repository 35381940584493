.filter-pill {
   display: flex;
   flex-direction: row;
   align-items: center;
   background: var(--background-gray);
   border: none;
   border-radius: 160px;
   padding: 8px 12px 8px 16px;
   cursor: pointer;
   gap: 8px;

   .body-standard-regular {
      color: var(--primary-dark-1);
      margin-right: 4px;
   }
}

.filter-pill:hover {
   background: var(--background-divider);
}

@media only screen and (max-device-width: 390px) {
   .filter-pill {
      margin-top: 6px;
      margin-bottom: 6px;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .filter-pill {
      margin-top: 2px;
      margin-bottom: 2px;
   }
}
