.back-button {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: var(--primary-dark-1);
   border: none;
   background: var(--background-gray);
   cursor: pointer;

   .back-arrow {
      margin-right: 7px;
      height: 10px;
      width: 14px;
      background-image: url(/public/images/back-arrow.svg);
   }
}

.back-button:hover {
   color: var(--primary-dark-2);

   .back-arrow {
      background-image: url(/public/images/back-arrow-hover.svg);
   }
}
