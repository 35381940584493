.admin {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   height: 100%;
   flex: 1 1 auto;

   .app-body {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: center;

      .content-row-wrapper {
         display: flex;
         flex-direction: column;
         align-items: center;
         background: var(--background-gray);
         height: 100%;
         width: 100%;
         padding-top: 40px;
         padding-bottom: 50px;

         .admin-content {
            display: flex;
            flex-direction: column;
            width: 80%;
            max-width: 1000px;
            gap: 10px;
            margin-bottom: 20px;

            .invite-button {
               margin-bottom: 20px;
               align-self: flex-start;
            }

            .admin-overflow-message {
               text-align: center;
               color: var(--primary-dark-2);
            }
         }
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .admin > .app-body > .content-row-wrapper .admin-content {
      width: 90%;
   }
}

@media only screen and (max-device-width: 391px) {
   .admin > .app-body > .content-row-wrapper .admin-content {
      width: 95%;
   }
}
