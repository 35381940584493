.customer-documents {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;

   .customer-documents-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 82px;
      margin-left: auto;
      margin-right: 20px;
   }

   .customer-documents-list {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-top: 12px;
      gap: 12px;
      margin-left: 20px;
      margin-right: 20px;
   }

   .customer-documents-help-text {
      margin-top: 80px;
      color: var(--primary-dark-2);
      align-self: center;
   }

   .secondary-button {
      align-self: center;
      margin-top: 12px;
      margin-bottom: 100px;
   }
}

@media only screen and (max-device-width: 744px) {
   .customer-documents {
      .customer-documents-header {
         margin-top: 50px;    
         margin-right: 40px;     
      }
      .customer-documents-list {
         margin-left: 40px;
         margin-right: 40px;
      }
   }
}
