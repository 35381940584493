.customer-details-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 58px;
   margin-left: 10px;
   margin-right: 10px;

   .customer-details-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 20px;
      margin-right: 20px;

      .customer-badges {
         display: flex;
         flex-wrap: wrap;
         gap: 8px;
         margin-top: 20px;
      }

      .customer-name {
         color: var(--primary-dark-1);
      }

      .customer-id {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 8px;

         .id-label {
            color: var(--primary-dark-2);
         }

         .id-number {
            color: var(--primary-dark-2);
         }
      }
   }

   .customer-details-interactive {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 20px;
      margin-right: 20px;

      .customer-details-contact {
         display: flex;
         flex-direction: row;
         gap: 8px;
      }

      .state-jurisdiction-wrapper {
         display: flex;
         flex-direction: column;
         align-items: flex-end;

         .state-jurisdiction-label {
            margin-top: 20px;
            margin-left: 5px;
            white-space: nowrap;
         }
      }
   }
}
@media only screen and (max-device-width: 744px) {
   .customer-details-header {
      flex-direction: column;

      .customer-details-interactive {
         margin-top: 24px;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;

         .state-jurisdiction-wrapper {
            margin-right: 20px;
         }
      }
   }
}
