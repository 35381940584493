.date-and-document {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: stretch;
   background: var(--background-white);
   border: none;
   padding: 16px 0px;

   .date-and-document-date {
      margin-bottom: 18px;
      color: var(--primary-dark-2);
   }

   .date-and-document-body {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .date-and-document-text {
         display: flex;
         flex-direction: column;
         align-items: flex-start;

         .document-summary {
            color: var(--primary-dark-1);
            margin-bottom: 2px;
         }

         .document-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;
            margin-top: 2px;

            img {
               height: 2px;
            }

            .body-small-regular {
               color: var(--primary-dark-3);
            }
         }
      }

      .download-button {
         margin-left: auto;
      }
   }
}
