.dates-and-documents-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: stretch;
   background: var(--background-white);
   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
   border-radius: 0px;
   flex-grow: 0.5;

   .dates-and-documents {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding: 40px 130px 40px 60px;
      background: var(--background-white);
      border-radius: 0px;

      .header-wrapper {
         display: flex;
         flex-direction: row;
         justify-content: space-between;

         .header-text-wrapper {
            display: flex;
            flex-direction: column;

            .body-small-semibold {
               color: var(--secondary-blue);
            }

            .h1-headline-light {
               margin-top: 4px;
               color: var(--primary-dark-1);
               margin-bottom: 36px;
            }
         }
      }

      .dates-and-documents-list {
         .date-and-document:not(:last-child) {
            border-bottom: 1px solid var(--background-divider);
         }
      }

      .dates-and-documents-soon {
         .dates-and-documents-soon-title {
            margin-top: 24px;
            color: var(--primary-dark-5);
         }

         .date-and-document:not(:last-child) {
            border-bottom: 1px solid var(--background-divider);
         }
      }

      .body-small-regular {
         margin-top: auto;
         align-self: center;
         color: var(--primary-dark-2);
      }

      .archive-link {
         color: (--primary-dark-1);
         cursor: pointer;
         margin-top: 4px;
         align-self: center;
      }
   }
}

@media only screen and (max-device-width: 744px) {
   .dates-and-documents-wrapper {
      display: block;
      position: fixed;
      width: 100%;
      padding: 0px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;

      .dates-and-documents {
         display: block;
         position: fixed;
         width: 100%;
         top: 87%;
         padding: 0px;
         border-top-right-radius: 20px;
         border-top-left-radius: 20px;

         .header-wrapper {
            .header-text-wrapper {
               .body-small-semibold {
                  margin-top: 33px;
                  margin-left: 27px;
               }

               .h1-headline-light {
                  margin-left: 27px;
                  margin-right: 0px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 24px;
                  margin-bottom: 27px;
               }
            }
         }

         .toggle-arrow {
            background-image: url(/public/images/dates-and-documents-up.svg);
            width: 126px;
            margin-right: -1px;
            height: 110px;
            border-top-right-radius: 20px;
         }

         .dates-and-documents-list {
            margin-left: 35px;
            margin-right: 35px;
         }

         .dates-and-documents-soon {
            display: none;
            visibility: hidden;
         }

         .body-small-regular {
            text-align: center;
         }

         .body-standard-semibold {
            text-align: center;
            margin-bottom: 20px;
         }
      }

      .focus-background {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         z-index: 1;
         background-color: black;
         opacity: 0.4;
      }

      .move-up {
         animation: move-up 0.5s ease-out forwards;
         z-index: 2;
         height: 100%;

         .toggle-arrow {
            background-image: url(/public/images/dates-and-documents-down.svg);
         }
      }

      .move-down {
         height: 100%;
         animation: move-down 0.5s ease-out forwards;
      }

      @keyframes move-down {
         from {
            top: 5%;
            height: 100%;
         }
         to {
            top: 87%;
         }
      }

      @keyframes move-up {
         from {
            top: 87%;
         }
         to {
            top: 5%;
            height: 100%;
         }
      }
   }
}
