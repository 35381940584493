.card-employee {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background-color: var(--background-white);
   border-radius: 8px;
   padding: 28px 40px 40px;
   border: 1px solid var(--background-white);
   cursor: default;

   &:not(:last-child) {
      margin-bottom: .825rem;
   }

   .card-employee-text {
      display: flex;
      flex-direction: column;
      flex-grow: 0.8;
      border-radius: 8px;

      .no-record-found {
         color: gray;
         text-align: center;
      }


      .employee-name {
         margin-top: 20px;
         color: var(--primary-dark-1);
      }

      .employee-id {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 8px;

         .id-label {
            color: var(--primary-dark-2);
         }

         .id-number {
            color: var(--primary-dark-2);
         }
      }
   }

   .document-card {
      display: flex;
      margin-right: 40px;
      visibility: hidden;
   }
}

.card-employee:hover {
   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

}

.no-badge-card:hover {
   background: linear-gradient(90deg,
         rgba(43, 147, 255, 0.2) 0%,
         rgba(228, 241, 255, 0.2) 100%),
      #ffffff;
}

@media only screen and (max-device-width: 744px) {
   .card-employee {
      flex-direction: column;
      margin-left: 21px;
      margin-right: 21px;
      padding: 28px 22px 20px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      overflow: hidden;

      .card-employee-text {
         align-self: flex-start;
      }
   }

   .card-employee:hover>.document-card {
      display: flex;
      visibility: inherit;
      margin-top: 20px;
   }

   .no-badge-card {
      background: linear-gradient(90deg,
            rgba(43, 147, 255, 0.2) 0%,
            rgba(228, 241, 255, 0.2) 100%),
         #ffffff;
   }
}
