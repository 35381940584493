.app-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   height: 18px;
   align-self: stretch;
   padding: 36px 39px;
   background: var(--background-white);

   .logo {
      width: 120px;
      height: 40px;
      flex: none;
      order: 0;
      align-self: flex-start;
      margin-top: -5px;
      background-image: url(/public/images/duidash-logo-dark.png);
      background-repeat: no-repeat;
      background-size: 120px;
      cursor: pointer;
   }

   .nav-buttons {
      display: flex;
      flex-direction: row;

      .help-button {
         display: flex;
         text-decoration: none;
         margin-left: 4px;
         margin-right: 36px;
         color: var(--primary-dark-1);
         cursor: pointer;
      }

      .nav-link-profile,
      .nav-link-login {
         display: flex;
         text-decoration: none;
         margin-left: 4px;
         margin-right: 4px;
         cursor: pointer;

         .profile-icon {
            margin-right: 8px;
         }

         .body-standard-semibold {
            flex: none;
            order: 0;
            flex-grow: 0;
            color: var(--primary-dark-1);
            text-decoration: none;
         }
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .app-header {
      padding-bottom: 24px;
      padding-top: 24px;
      padding-left: 20px;
      padding-right: 20px;

      .logo {
         width: 87px;
         height: 33px;
         background-size: 87px;
      }

      .nav-buttons > .help-button {
         margin-right: 16px;
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .app-header {
      padding-bottom: 19px;
      padding-top: 19px;
   }
}
