.admin-paging-control {
   display: flex;
   flex-direction: row;
   align-items: center;
   align-self: center;
   gap: 10px;

   .admin-paging-button {
      padding: 10px;
   }

   .admin-paging-button:active {
      background: var(--primary-dark-2);
   }

   .admin-paging-message {
      color: var(--primary-dark-2);
   }

   .admin-paging-arrow-back {
      transform: scaleX(-1);
   }
}
