.email-button {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   min-width: 42px;
   min-height: 42px;
   background-color: var(--primary-dark-5);
   border-radius: 8px;
   border: none;
   cursor: pointer;

   .email-button-icon {
      width: 20px;
      height: 16px;
      background-image: url(/public/images/email.svg);
   }

   input {
      visibility: hidden;
      display: none;
   }

   .email-button-dropdown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 48;
      left: -100;
      box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.15);
      background: var(--background-white);
      border-radius: 8px;
      border: 1px solid;
      border-color: var(--background-divider);
      padding: 4px 4px 4px 8px;
      gap: 7px;
      z-index: 1;
      cursor: auto;

      .email-button-dropdown-email {
         display: flex;
         justify-content: flex-start;
         white-space: nowrap;
      }
   }
}

.email-button:hover {
   background: var(--primary-dark-2);
}

.email-checked {
   background: #c45e06;
}

.email-checked:hover {
   background: #c45e06;
}

.email-disabled:hover {
   background: var(--background-divider);
}

.email-disabled {
   border: 1px solid;
   border-color: var(--primary-dark-3);
   background: var(--background-divider);
   cursor: auto;

   .email-button-icon {
      background-image: url(/public/images/email-disabled.svg);
   }
}
