.primary-button {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 16px 40px;
   gap: 8px;
   border-radius: 160px;
   background: var(--primary-dark-1);
   border: none;
   cursor: pointer;

   .body-large-semibold {
      white-space: nowrap;
   }
}

.primary-button:hover {
   background: var(--primary-dark-2);
}

.primary-button:active {
   background: var(--primary-green);
}
