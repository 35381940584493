.invite-button {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 12px;
   gap: 8px;
   border-radius: 8px;
   background: var(--background-white);
   color: #000000;
   border: 1px solid var(--background-divider);
   min-width: 174px;
   cursor: pointer;

   .invite-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background: var(--primary-dark-1);
   }
}

.invite-button:hover {
   background: var(--background-gray);

   .invite-icon {
      background: var(--primary-dark-2);
   }
}

.invite-button:active {
   background: var(--background-white);
   color: var(--primary-green);
   border-color: var(--primary-green);

   .invite-icon {
      background: var(--primary-green);
   }
}

@media only screen and (max-device-width: 744px) {
   .invite-button {
      margin-left: 21px;
      margin-right: 5px;
   }
}
