.search {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   width: 100%;

   .search-bar {
      display: flex;
      flex-direction: row;
      width: 80%;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--background-divider);
      position: relative;

      .search-icon {
         display: flex;
         width: 18px;
         height: 18px;
         margin: 12px;
         background-image: url(/public/images/search.svg);
      }

      .search-input {
         display: flex;
         flex-grow: 1;
         position: relative;
         margin-top: 18px;
         margin-bottom: 18px;
         border: none;
         z-index: 10;
         opacity: 0;
      }

      .search-input:focus {
         outline: none;
      }

      .search-input:disabled {
         background-color: var(--background-white);
      }

      .search-input:placeholder-shown {
         opacity: 0;
      }

      .search-input:focus {
         opacity: 1;
      }

      .search-input:not(:disabled):active {
         opacity: 1;
      }

      .search-input:not(:placeholder-shown) {
         opacity: 1;
      }

      .placeholder-text {
         position: absolute;
         top: 19px;
         left: 44px;

         .placeholder-first,
         .placeholder-search {
            color: var(--primary-dark-2);
            position: relative;
            white-space: nowrap;
         }

         .placeholder-second {
            color: var(--primary-dark-4);
            position: relative;
            white-space: nowrap;
         }
      }

      .placeholder-hidden {
         display: none;
         visibility: hidden;
      }

      .clear-button {
         visibility: hidden;
      }
   }

   .search-bar:focus-within {
      border-color: var(--primary-green);

      .search-icon {
         background-image: url(/public/images/search-focused.svg);
      }
   }

   .search-query-populated {
      .clear-button {
         visibility: visible;
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .search > .search-bar {
      width: 90%;
      .search-icon {
         margin: 0px;
      }

      .search-input {
         margin-top: 10px;
         margin-bottom: 10px;
         margin-left: 10px;
         font-size: 24px;
         line-height: 32px;
      }

      .placeholder-text {
         display: flex;
         align-items: center;
         top: 12px;
         left: 30px;

         .placeholder-search {
            font-size: 22px;
            line-height: 32px;
         }

         .placeholder-first,
         .placeholder-second {
            visibility: hidden;
            display: none;
         }
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .search > .search-bar {
      width: 90%;
      .search-input {
         margin-top: 10px;
         margin-bottom: 10px;
         margin-left: 10px;
         font-size: 24px;
         line-height: 32px;
      }

      .placeholder-text {
         display: flex;
         align-items: center;
         top: 12px;
         left: 52px;

         .placeholder-first,
         .placeholder-search {
            font-size: 24px;
            line-height: 32px;
         }

         .placeholder-second {
            visibility: hidden;
            display: none;
         }
      }
   }
}

@media only screen and (max-device-width: 980px) {
   .search > .search-bar > .placeholder-text > .placeholder-second {
      visibility: hidden;
      display: none;
   }
}

@media only screen and (max-device-width: 480px) {
   .search > .search-bar > .placeholder-text > .placeholder-first,
   .placeholder-second {
      visibility: hidden;
      display: none;
   }
}
