.modal {
   display: flex;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow-y: auto;
   flex-direction: column;
   align-items: center;
   z-index: 11;
   background-color: rgba(0, 0, 0, 0.5);

   .modal-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 40px;
      gap: 40px;
      background: var(--background-white);
      box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      z-index: 2;
      max-width: 365px;
      margin: auto;

      .modal-nav-wrapper {
         display: flex;
         flex-direction: row;
         justify-content: space-between;

         .body-standard-semibold {
            color: var(--primary-green);
         }
      }

      .modal-buttons-wrapper {
         display: flex;
         flex-direction: row;
         align-self: stretch;
         align-items: center;
         justify-content: stretch;
         gap: 5px;

         .secondary-button {
            padding-top: 16px;
            padding-bottom: 16px;
            flex-grow: 1;
         }

         .primary-button {
            flex-grow: 1;
         }
      }
   }
}

.modal-hidden {
   display: none;
   visibility: hidden;
}

@media only screen and (max-device-width: 744px) {
   .modal > .modal-wrapper {
      padding: 32px;
      gap: 32px;
   }
}
