.filter-search-item {
   display: flex;
   padding: 14px 8px;
   border-radius: 4px;
   cursor: pointer;
   overflow-x: hidden;
   text-overflow: ellipsis;
   min-height: 20px;
   white-space: nowrap
}

.filter-search-item:hover{
   background: var(--secondary-green-hue);
}

.filter-highlighted {
   background: var(--secondary-green-hue);
}
.filter-not-highlighted:hover {
   background: var(--background-divider);
}
