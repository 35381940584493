.modal-textbox {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   background-color: var(--background-gray);
   border: 1px solid;
   border-color: var(--background-divider);
   border-radius: 8px;
   padding: 10px 22px 10px 16px;
   gap: 4px;
   cursor: text;

   .modal-textbox-label {
      color: var(--primary-dark-2);

      .modal-textbox-required{
         margin-left: 2px;
         color: var(--secondary-attention);
      }
   }

   .modal-textbox-input {
      border: none;
      background: var(--background-gray);
   }

   .modal-textbox-input:focus {
      outline: none;
   }
}

.modal-textbox-focused {
   border-bottom: 1px solid var(--primary-green);
   border-bottom-left-radius: 0px;
   border-bottom-right-radius: 0px;
}
