.quick-filter {
   display: flex;
   flex-direction: row;
   align-items: center;
   background: var(--background-gray);
   border: none;
   border-radius: 160px;
   padding: 8px 12px 8px 16px;
   cursor: auto;

   .body-standard-regular {
      color: var(--primary-dark-3);
      margin-right: 4px;
   }

   .quick-filter-count {
      display: flex;
      background-image: url(/public/images/ellipse-1.svg);
      width: 24px;
      height: 25px;
      align-items: center;
      justify-content: center;

      .label-xsmall {
         color: var(--primary-dark-2);
      }
   }

   .checkbox {
      visibility: hidden;
      display: none;
   }
}

.quick-filter-enabled {
   cursor: pointer;
   /* firefox compatibility */
   .body-standard-regular {
      color: var(--primary-dark-2);
   }
   .quick-filter-count > .label-xsmall {
      color: var(--primary-dark-1);
   }
}

.quick-filter-enabled:hover {
   /* firefox compatibility */
   background: var(--background-divider);

   .body-standard-regular {
      color: var(--primary-dark-2);
   }
}

.quick-filter-selected {
   /* firefox compatibility */
   background: var(--primary-dark-1);

   .body-standard-regular {
      color: var(--background-white);
   }

   .quick-filter-count {
      background-image: url(/public/images/ellipse-2.svg);
   }
}

.quick-filter-selected:hover {
   /* firefox compatibility */
   background: var(--primary-dark-1);

   .body-standard-regular {
      color: var(--background-white);
   }
}

@media only screen and (max-device-width: 390px) {
   .quick-filter {
      margin-top: 6px;
      margin-bottom: 6px;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .quick-filter {
      margin-top: 2px;
      margin-bottom: 2px;
   }
}
