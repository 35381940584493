.sort-dropdown-item {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 14px 16px;
   border-radius: 4px;
   gap: 6px;
   cursor: pointer;

   .sort-arrow {
      width: 9px;
      height: 13px;
   }

   .up {
      background-image: url(/public/images/arrow.svg);
      transform: rotate(180deg);
   }

   .down {
      background-image: url(/public/images/arrow.svg);
   }
}

.sort-dropdown-item-checked {
   background: var(--secondary-green-hue);
}

.sort-dropdown-item-not-checked:hover {
   background: var(--background-divider);

   .ascDesc {
      background-image: url(/public/images/arrow-up-gray.svg);
   }
}
