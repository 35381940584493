.impersonate-header {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 8px 10px 8px 10px;
   background: var(--primary-green);
   gap: 12px;

   .impersonate-header-text {
      display: flex;
      cursor: pointer;
      color: var(--background-white);
      text-align: center;
   }
}

@media only screen and (max-device-width: 390px) {
   .impersonate-header {
      padding: 12px 10px 12px 10px;
   }
}
