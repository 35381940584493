.copy-button {
   display: flex;
   justify-content: center;
   align-items: center;
   background: var(--primary-green);
   border: 3px solid var(--background-white);
   border-radius: 8px;
   padding: 12px 16px;
   width: 81px;
   cursor: pointer;
}

.copied {
   background: var(--secondary-blue-green);
}
