.firm-sort-filter {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin: 25px auto;
   width: 80%;

   .firm-filters-sort {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      .sort-button {
         background-color: var(--primary-dark-5);
         display: flex;
         margin-left: auto;
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .firm-sort-filter {
      width: 90%;
   }

   .firm-filters-sort {
      .sort-button {
         margin-right: 0;
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .firm-sort-filter {
      width: 90%;
   }
}
