.deactivate-affiliate-button {
   border: none;
   background: var(--background-divider);
   border-radius: 4px;
   padding: 4px 8px;
   cursor: pointer;
}

.deactivate-affiliate-button:hover {
   color: var(--primary-dark-2);
}

.deactivate-affiliate-button:active {
   background: var(--primary-dark-1);
   color: var(--primary-dark-4);
}

.deactivate-affiliate-disabled {
   border: none;
   background: var(--background-divider);
   border-radius: 4px;
   padding: 4px 8px;
   color: var(--primary-dark-2);
   cursor: auto;
}

.deactivate-affiliate-disabled:hover {
   color: var(--primary-dark-2);
}

.deactivate-affiliate-disabled:active {
   background: var(--background-divider);
   color: var(--primary-dark-2);
}
