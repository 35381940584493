.card-customer {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   background-color: var(--background-white);
   border-radius: 8px;
   padding: 28px 40px 40px;
   border: 1px solid var(--background-white);
   cursor: pointer;

   &:not(:last-child) {
      margin-bottom: .825rem;
   }

   .card-customer-text {
      display: flex;
      flex-direction: column;
      flex-grow: 0.8;
      border-radius: 8px;

      .customer-badges {
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         gap: 8px;
      }

      .customer-name {
         margin-top: 20px;
         color: var(--primary-dark-1);
      }

      .customer-id {
         display: flex;
         flex-direction: row;
         align-items: center;
         gap: 8px;

         .id-label {
            color: var(--primary-dark-2);
         }

         .id-number {
            color: var(--primary-dark-2);
         }
      }
   }

   .document-card {
      display: flex;
      margin-right: 40px;
      visibility: hidden;
   }
}

.card-customer:hover {
   box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

   .document-card {
      visibility: inherit;
   }
}

.new-client-card:hover {
   background: linear-gradient(
         88.5deg,
         rgba(149, 189, 118, 0.2) 0.74%,
         rgba(186, 210, 167, 0.2) 51.39%,
         rgba(205, 221, 191, 0.2) 90.64%,
         rgba(244, 244, 244, 0.2) 120.68%
      ),
      #ffffff;
}

.recent-violation-card:hover {
   background: linear-gradient(
      180deg,
      rgba(240, 163, 163, 0.1) 0%,
      rgba(214, 0, 0, 0.1) 100%
   );
}

.alert-device-card:hover {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
}

.calibration-overdue-card:hover {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
}

.archive-card:hover {
   background: linear-gradient(
         90deg,
         rgba(255, 201, 88, 0.2) 0%,
         rgba(255, 248, 233, 0.2) 100%
      ),
      #ffffff;
}

.no-badge-card:hover {
   background: linear-gradient(
         90deg,
         rgba(43, 147, 255, 0.2) 0%,
         rgba(228, 241, 255, 0.2) 100%
      ),
      #ffffff;
}

@media only screen and (max-device-width: 744px) {
   .card-customer {
      flex-direction: column;
      margin-left: 21px;
      margin-right: 21px;
      padding: 28px 22px 20px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      overflow: hidden;

      .card-customer-text {
         align-self: flex-start;
      }

      .document-card {
         align-self: center;
         margin: 0;
         display: none;
         visibility: hidden;
      }
   }

   .card-customer:hover > .document-card {
      display: flex;
      visibility: inherit;
      margin-top: 20px;
   }

   .new-client-card {
      background: linear-gradient(
            88.5deg,
            rgba(149, 189, 118, 0.2) 0.74%,
            rgba(186, 210, 167, 0.2) 51.39%,
            rgba(205, 221, 191, 0.2) 90.64%,
            rgba(244, 244, 244, 0.2) 120.68%
         ),
         #ffffff;
   }

   .recent-violation-card {
      background: linear-gradient(
         180deg,
         rgba(240, 163, 163, 0.1) 0%,
         rgba(214, 0, 0, 0.1) 100%
      );
   }

   .alert-device-card {
      background: linear-gradient(
            90deg,
            rgba(255, 201, 88, 0.2) 0%,
            rgba(255, 248, 233, 0.2) 100%
         ),
         #ffffff;
   }

   .calibration-overdue-card {
      background: linear-gradient(
            90deg,
            rgba(255, 201, 88, 0.2) 0%,
            rgba(255, 248, 233, 0.2) 100%
         ),
         #ffffff;
   }

   .archive-card {
      background: linear-gradient(
            90deg,
            rgba(255, 201, 88, 0.2) 0%,
            rgba(255, 248, 233, 0.2) 100%
         ),
         #ffffff;
   }

   .no-badge-card {
      background: linear-gradient(
            90deg,
            rgba(43, 147, 255, 0.2) 0%,
            rgba(228, 241, 255, 0.2) 100%
         ),
         #ffffff;
   }
}
