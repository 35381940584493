.search-dropdown-recent {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   padding: 16px 40px 16px 30px;
   border-radius: 10px;
   margin-left: 20px;
   margin-right: 20px;
   cursor: pointer;

   img {
      margin-right: 12px;
      visibility: hidden;
   }

   .search-name {
      color: var(--primary-dark-2);
      justify-self: flex-start;
   }

   .search-recent {
      color: var(--primary-dark-3);
      margin-left: 10px;
   }

   .search-id-label {
      color: var(--primary-dark-3);
      margin-right: 15px;
      margin-left: auto;
   }

   .search-id {
      color: var(--primary-dark-3);
   }
}

.invisible {
   display: none;
   visibility: hidden;
}

.search-dropdown-recent:hover {
   background: var(--secondary-green-hue);

   img {
      visibility: visible;
   }

   .search-name,
   .search-id-label,
   .search-id {
      color: var(--secondary-blue-green);
   }
}

@media only screen and (max-device-width: 390px) {
   .search-dropdown-recent > img {
      visibility: hidden;
      display: none;
   }

   .search-name,
   .search-id-label,
   .search-id,
   .search-recent {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
   }
}

@media only screen and (max-device-width: 390px) {
   .search-dropdown-recent > img {
      visibility: hidden;
      display: none;
   }

   .search-name,
   .search-id-label,
   .search-id,
   .search-recent {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .search-dropdown-recent {
      .search-name,
      .search-id-label,
      .search-id,
      .search-recent {
         font-size: 24px;
         line-height: 32px;
      }
   }
}
