.unverified-view {
   display: flex;
   flex-direction: column;
   align-items: stretch;
   background-color: var(--background-white);

   margin-top: 66px;
   margin-bottom: 138px;
   margin-left: 10px;
   margin-right: 10px;

   .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .unverified-content {
         display: flex;
         flex-direction: column;
         background: var(--background-gray);
         align-items: center;
         flex-grow: 0.4;
         padding-bottom: 60px;
         border-radius: 10px;

         .in-progress {
            margin-top: 60px;
         }

         .large-type {
            display: flex;
            text-align: center;
            margin-top: 32px;
            max-width: 541px;
         }

         .body-large-light {
            display: flex;
            text-align: center;
            margin-top: 8px;
            max-width: 471px;
         }

         .primary-button {
            margin-top: 32px;
         }
      }
   }
}

@media only screen and (max-device-width: 390px) {
   .unverified-view {
      margin-top: 25px;

      .content-wrapper > .unverified-content {
         .large-type {
            max-width: 280px;
            font-weight: 300;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            opacity: 0.8;
         }

         .body-large-light {
            width: 325px;
         }
      }
   }
}

@media only screen and (min-device-width: 391px) and (max-device-width: 744px) {
   .unverified-view > .content-wrapper > .unverified-content > .large-type {
      width: 500px;
   }
}
