.location-button {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;
   min-width: 42px;
   min-height: 42px;
   background-color: var(--primary-dark-5);
   border-radius: 8px;
   border: none;
   cursor: pointer;

   .location-button-icon {
      width: 18px;
      height: 22px;
      background-image: url(/public/images/location.svg);
   }

   input {
      visibility: hidden;
      display: none;
   }

   .location-button-dropdown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 48;
      left: -50;
      box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.15);
      background: var(--background-white);
      border-radius: 8px;
      border: 1px solid;
      border-color: var(--background-divider);
      padding: 4px 4px 4px 8px;
      gap: 7px;
      z-index: 1;
      cursor: auto;

      .location-button-dropdown-address-wrapper {
         display: flex;
         flex-direction: column;

         .location-button-dropdown-address {
            display: flex;
            justify-content: flex-start;
         }
      }
   }
}

.location-button:hover {
   background: var(--primary-dark-2);
}

.location-checked {
   background: #c45e06;
}

.location-checked:hover {
   background: #c45e06;
}

.location-disabled:hover {
   background: var(--background-divider);
}

.location-disabled {
   border: 1px solid;
   border-color: var(--primary-dark-3);
   background: var(--background-divider);
   cursor: auto;

   .location-button-icon {
      background-image: url(/public/images/location-disabled.svg);
   }
}
